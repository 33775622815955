export default {
  methods: {
    statusGrafico (e) {
      e.preventDefault()
      let item = e.sender.dataItem(e.masterRow)
      // eslint-disable-next-line no-undef
      $('<div/>').appendTo(e.detailCell).kendoChart({
        dataSource: {
          transport: {
            read: {
              url: `${this.$url}/relatorio/usuarios/data`,
              data: {
                dataini: this.filtro.dataini,
                datafim: this.filtro.datafim,
                usuario: this.filtro.usuario,
                status: item.sid,
                datatipo: this.filtro.datatipo
              }
            }
          },
          schema: {
            parse: data => {
              return data.map(item => {
                item.v1 = parseFloat(item.v1)
                return item
              })
            }
          }
        },
        legend: {
          visible: false
        },
        seriesDefaults: {
          type: 'column',
          labels: {
            visible: true,
            background: 'transparent'
          }
        },
        valueAxis: {
          type: 'log',
          title: {
            text: 'Valor'
          }
        },
        series: [{
          field: 'v1',
          name: 'Valor',
          format: '{0:n2}',
          tooltip: {
            visible: true
          }
        }, {
          field: 'quant',
          name: 'Quant',
          tooltip: {
            visible: true
          }
        }],
        theme: 'material',
        categoryAxis: {
          field: 'nome',
          name: 'Dias',
          majorGridLines: {
            visible: true
          },
          line: {
            visible: true
          },
          title: {
            text: 'Dias'
          },
          labels: {
            rotation: 'auto'
          }
        },
        yAxis: {
          title: {
            text: 'Quantidade'
          }
        }
      })
    }
  }
}
