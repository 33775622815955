export default {
  computed: {
    filter () {
      return {
        logic: 'and',
        filters: [{
          field: 'cod',
          operator: 'contains',
          value: this.filtro.cod
        }, {
          field: 'nome',
          operator: 'contains',
          value: this.filtro.cliente
        }, {
          field: 'dt_instalacao',
          operator: 'contains',
          value: this.filtro.datavenda,
          value2: this.filtro.datatipo
        }, {
          field: 'periodo',
          operator: 'contains',
          value: this.filtro.dataini,
          value2: this.filtro.datafim,
          value3: this.filtro.datatipo
        }, {
          field: 'status_id',
          operator: 'contains',
          value: this.filtro.status
        }, {
          field: 'usuario_id',
          operator: 'contains',
          value: this.filtro.usuario
        }, {
          field: 'instalacao_id',
          operator: 'contains',
          value: this.filtro.instalador
        }, {
          field: 'pg_realizado',
          operator: 'contains',
          value: this.filtro.tx
        }, {
          field: 'categoria_id',
          operator: 'contains',
          value: this.filtro.categoria_id
        }, {
          field: 'periodo_id',
          operator: 'contains',
          value: this.filtro.periodo_id
        }]
      }
    }
  }
}
