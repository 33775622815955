import Vue from 'vue'

export default {
  computed: {
    urlDataSourceTaxa () {
      return `${this.$url}/relatorio/taxa`
    }
  },
  data () {
    return {
      columnsTaxa: [{
        field: 'posicao',
        title: ' ',
        width: 50,
        attributes: {
          class: 'text-center'
        }
      }, {
        field: 'nome',
        title: 'Taxa de Adesão',
        template: this.itemTemplateTaxa
      }, {
        field: 'v1',
        title: 'Valor',
        format: 'R$ {0:n}',
        footerTemplate: '<div class="text-right">R$ #: kendo.toString(sum, "n") #</div>',
        attributes: {
          class: 'text-right'
        }
      }, {
        field: 'quant',
        title: 'Quant',
        footerTemplate: '<div class="text-center">#: kendo.toString(sum, "n0") #</div>',
        attributes: {
          class: 'text-center'
        }
      }],
      gridTaxa: [{
        resizable: true,
        height: 500
      }]
    }
  },
  methods: {
    itemTemplateTaxa (e) {
      var vm = this
      return {
        template: Vue.component('temp', {
          template: `
            <a :href='url(templateArgs.sid)'
              style='color: #337ab7;' @click.prevent="buttonClick(templateArgs.sid)">
              {{ templateArgs.nome }}
            </a>
           `,
          data () {
            return {
              templateArgs: {}
            }
          },
          methods: {
            url () {
              return `/sistema/vendas`
            },
            buttonClick () {
              let filtro = JSON.parse(JSON.stringify(vm.filtro))
              filtro.tx = this.templateArgs.sid
              localStorage.setItem('_filtroVendas', JSON.stringify(filtro))
              vm.$router.push('/sistema/vendas')
            }
          }
        }),
        templateArgs: e
      }
    }
  }
}
