<template lang="pug">
form
  .row
    .col-md-3
      label Clientes:
      input.form-control(v-model='filtro.cliente', placeholder='Clientes')
    .col-md-3
      select-form(v-model='filtro.tipo_emprestimo', :value='filtro.tipo_emprestimo',name='Tipo de Emprestimo', url='/tipo-emprestimo', id='id', titulo='nome')
    .col-md-3
      //select-form(v-model='filtro.status', :value='filtro.status', name='Status', url='/status/vendas', id='id', titulo='nome', :resetar='resetar')
      select-multi-form(v-model='filtro.status', :value='filtro.status', name='Status', url='/status/vendas', id='id', titulo='nome', :resetar='resetar')
    .col-md-3
      select-form(v-model='filtro.orgao', :value='filtro.orgao', name='Orgão', url='/orgao', id='id', titulo='nome')
  .row
    .col-md-3
      label Periodo:
      br
      date-range-picker(:opens='opens', @update='updateValues', :locale-data='locale', :startDate='startDate', :endDate='endDate')
    .col-md-3
      label(for='tipo') Tipo Data
      select.form-control(v-model='filtro.datatipo')
        option(:value='d.id', v-for='d in datatipo') {{d.nome}}
    .col-md-3
      select-form(v-model='filtro.usuario', :value='filtro.usuario', name='Usuário', url='/usuarios', id='id', titulo='name')
    .col-md-3
        br
        button.btn.btn-primary(@click.prevent='getLimparFiltro') Limpar
</template>

<script>
import SelectForm from '@/components/form/SelectForm'
import SelectMultiForm from '@/components/form/SelectMultiForm'
import DateRangePicker from '@/components/form/DateRangePicker/DateRangePicker'
import moment from 'moment'

export default {
  props: ['filtro'],
  components: {
    SelectForm,
    SelectMultiForm,
    DateRangePicker
  },
  data () {
    return {
      resetar: false,
      opens: 'ltr',
      locale: {
        direction: 'ltr',
        format: 'DD/MM/YYYY',
        separator: ' - ',
        applyLabel: 'Aplicar',
        cancelLabel: 'Cancelar',
        weekLabel: 'W',
        customRangeLabel: 'Custom Range',
        daysOfWeek: moment.weekdaysMin(),
        monthNames: moment.monthsShort(),
        firstDay: 1,
        open: true
      },
      startDate: moment().startOf('day').startOf('month').format('YYYY-MM-DD'),
      endDate: moment(),
      datatipo: [{
        id: 'dt_venda',
        nome: 'Data de Venda'
      }, {
        id: 'datacad',
        nome: 'Data de Cadastro'
      }, {
        id: 'dataatual',
        nome: 'Ultima Atualização'
      }]
    }
  },
  methods: {
    updateValues (values) {
      this.filtro.dataini = moment(values.startDate).format('YYYY-MM-DD')
      this.filtro.datafim = moment(values.endDate).format('YYYY-MM-DD')
    },
    getLimparFiltro () {
      this.resetar = !this.resetar
      sessionStorage.removeItem('_/status/vendas_select')
      this.$emit('updateFiltro', true)
    }
  },
  mounted () {
    let filtro = localStorage.getItem('_filtroVendas') ? JSON.parse(localStorage.getItem('_filtroVendas')) : null
    if (filtro) {
      this.startDate = moment(filtro.dataini)
      this.endDate = moment(filtro.datafim)
    }
  }
}
</script>

<style lang="stylus" scoped>

</style>
