export default {
  computed: {
    urlDataSourceData () {
      return `${this.$url}/relatorio/data`
    }
  },
  data () {
    return {
      columnsData: [{
        field: 'posicao',
        title: ' ',
        width: 50,
        attributes: {
          class: 'text-center'
        }
      }, {
        field: 'nome',
        title: 'Data'
      }, {
        field: 'v1',
        title: 'Valor',
        format: 'R$ {0:n}',
        footerTemplate: '<div class="text-right">R$ #: kendo.toString(sum, "n") #</div>',
        attributes: {
          class: 'text-right'
        }
      }, {
        field: 'quant',
        title: 'Quant',
        footerTemplate: '<div class="text-center">#: kendo.toString(sum, "n0") #</div>',
        attributes: {
          class: 'text-center'
        }
      }],
      gridData: [{
        resizable: true,
        height: 500
      }]
    }
  }
}
