<template lang="pug">
div
  page-header(:title='title')
  filtro-form(:filtro='filtro', @updateFiltro='getLimparFiltro')
  br
  div
    vue-element-loading(:active='isActive', spinner='bar-fade-scale', color='#428BCA')
    .page-header
      .row
        .col-md-12
          h1 Usuário
    grid(:url='urlDataSourceUsuario', :columns='columnsUsuario', :grid='gridUsuario', :source='source')
    br
    .page-header
      .row
        .col-md-12
          h1 Status
    grid(:url='urlDataSourceStatus', :columns='columnsStatus', :grid='gridStatus', :source='source')
    br
    .page-header
      .row
        .col-md-12
          h1 Tipo de Emprestimo
    grid(:url='urlDataSourceInstaladores', :columns='columnsInstaladores', :grid='gridInstaladores', :source='source')
    br
    .page-header
      .row
        .col-md-12
          h1 Orgão
    grid(:url='urlDataSourceTaxa', :columns='columnsTaxa', :grid='gridTaxa', :source='source')
    br
    .page-header
      .row
        .col-md-12
          h1 Data
    grid(:url='urlDataSourceData', :columns='columnsData', :grid='gridData', :source='source')

</template>

<script>
import VueElementLoading from 'vue-element-loading'
import moment from 'moment'
import numeral from 'numeral'
import 'numeral/locales/pt-br'

import PageHeader from '@/components/PageHeader'
import Grid from '@/components/Grid'
// import req from '@/components/request'
// import {erro} from '@/components/Message'
import SelectForm from '@/components/form/SelectForm'
import FiltroForm from './Filtro'
import DateRangePicker from '@/components/form/DateRangePicker/DateRangePicker'
import UsuarioGrafico from '@/mixins/Relatorio/UsuarioGrafico'
import statusGrafico from '@/mixins/Relatorio/statusGrafico'
import Filters from '@/mixins/Relatorio/Filters'
import GridUsuario from '@/mixins/Relatorio/GridUsuario'
import GridStatus from '@/mixins/Relatorio/GridStatus'
import GridInstaladores from '@/mixins/Relatorio/GridInstaladores'
import GridTaxa from '@/mixins/Relatorio/GridTaxa'
import GridData from '@/mixins/Relatorio/GridData'

numeral.locale('pt-BR')

export default {
  components: {
    PageHeader,
    VueElementLoading,
    Grid,
    SelectForm,
    DateRangePicker,
    FiltroForm
  },
  mixins: [GridUsuario, GridStatus, GridInstaladores, GridTaxa, GridData, UsuarioGrafico, statusGrafico, Filters],
  computed: {
    source () {
      return [{
        filter: this.filter
      }, {
        serverFiltering: true
      }, {
        schemaParse: this.parse
      }, {
        aggregate: this.aggregate
      }]
    }
  },
  watch: {
    'filtro.cod' () {
      localStorage.setItem('_filtroVendas', JSON.stringify(this.filtro))
    },
    'filtro.cliente' () {
      localStorage.setItem('_filtroVendas', JSON.stringify(this.filtro))
    },
    'filtro.datavenda' () {
      localStorage.setItem('_filtroVendas', JSON.stringify(this.filtro))
    },
    'filtro.status' () {
      localStorage.setItem('_filtroVendas', JSON.stringify(this.filtro))
    },
    'filtro.usuario' () {
      localStorage.setItem('_filtroVendas', JSON.stringify(this.filtro))
    },
    'filtro.instalador' () {
      localStorage.setItem('_filtroVendas', JSON.stringify(this.filtro))
    },
    'filtro.datatipo' () {
      localStorage.setItem('_filtroVendas', JSON.stringify(this.filtro))
    },
    'filtro.tipo_emprestimo' () {
      localStorage.setItem('_filtroVendas', JSON.stringify(this.filtro))
    },
    'filtro.orgao' () {
      localStorage.setItem('_filtroVendas', JSON.stringify(this.filtro))
    },
    'filtro.dataini' () {
      localStorage.setItem('_filtroVendas', JSON.stringify(this.filtro))
    },
    'filtro.datafim' () {
      localStorage.setItem('_filtroVendas', JSON.stringify(this.filtro))
    },
    'filtro.periodo_id' () {
      localStorage.setItem('_filtroVendas', JSON.stringify(this.filtro))
    }
  },
  data () {
    return {
      title: 'Relatório',
      isActive: false,
      aggregate: [{
        field: 'v1',
        aggregate: 'sum'
      }, {
        field: 'frete',
        aggregate: 'sum'
      }, {
        field: 'quant',
        aggregate: 'sum'
      }, {
        field: 'desconto',
        aggregate: 'sum'
      }, {
        field: 'total',
        aggregate: 'sum'
      }],
      filtro: {
        cod: '',
        cliente: '',
        datavenda: '',
        status: '',
        usuario: '',
        instalador: '',
        datatipo: 'dt_venda',
        tipo_emprestimo: '',
        orgao: '',
        dataini: moment().startOf('month').format('YYYY-MM-DD'),
        datafim: moment().format('YYYY-MM-DD'),
        periodo_id: ''
      }
    }
  },
  methods: {
    getVendas () {

    },
    buildSortedQuery (args) {
      return Object.keys(args)
        .sort()
        .map(key => {
          return `${window.encodeURIComponent(key)}=${window.encodeURIComponent(args[key])}`
        })
        .join('&')
    },
    parse (d) {
      let i = 1
      d.map(item => {
        item.v1 = parseFloat(item.v1)
        item.frete = parseFloat(item.frete)
        item.desconto = parseFloat(item.desconto)
        item.quant = parseInt(item.quant)
        item.total = (item.v1 + item.frete - item.desconto)
        item.posicao = `${i}°`
        i++
        return item
      })
      return d
    },
    getLimparFiltro () {
      let filtro = {
        cod: null,
        cliente: null,
        datavenda: null,
        status: null,
        usuario: null,
        instalador: null,
        datatipo: 'dt_venda',
        tipo_emprestimo: null,
        orgao: null,
        dataini: moment().startOf('day').startOf('month').format('YYYY-MM-DD'),
        datafim: moment().format('YYYY-MM-DD')
      }
      localStorage.setItem('_filtroVendas', JSON.stringify(filtro))
      this.filtro = filtro
    }
  },
  mounted () {
    let filtro = localStorage.getItem('_filtroVendas') ? JSON.parse(localStorage.getItem('_filtroVendas')) : null
    if (filtro) {
      this.startDate = moment(filtro.dataini)
      this.endDate = moment(filtro.datafim)
      this.filtro = filtro
    }
  }
}
</script>

<style lang="stylus">
.size2
  font-size 11px

.text-right
  text-align right !important

.text-center
  text-align center !important
</style>
